const firebaseConfig = {
  projectId: "demonstration-lrhch",
  appId: "1:487077600552:web:22ac4ef6bd4495fd24441d",
  databaseURL:
    "https://demonstration-lrhch-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "demonstration-lrhch.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyBPrv1a-GIjaPFSBJ73C8uMZYD526U2Vfo",
  authDomain: "demonstration-lrhch.firebaseapp.com",
  messagingSenderId: "487077600552",
};

export default firebaseConfig;
